*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box !important;
}

.mainDetail{
    overflow-y: hidden;
}

.mainDetailHeader{
    padding: 20px 0px;
    text-align: center;
}

.detailProContainer{
    overflow: hidden;
}

.proImgWrap{
    width: 55px;
    border-radius: 15px;
}

.detailProName{
    line-height: 23px;
}

.detailPriceTag{
    border-radius: 15px;
    line-height: 20px;
    width: calc(95% / 3);
    border-width: 2px !important;
}

.mainDetailProAboutTxt{
    color: gray !important;
    line-height: 17px;
}

.checkBtn{
    border-radius: 15px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    width: min(100%, 600px) !important;
    text-align: center;
    font-size: 16px !important;
}

.buy-btn{
    font-size: 20px !important;
}

.checkCall, .checkWhat{
    padding: 13px 13px;
    border-radius: 15px !important;
}

.checkBtnWrap{
    width: 90%;
    position: fixed;
    left: 5%;
    bottom: 5px;
    text-align: center;
    z-index: 15;
}

.featuresUl{
    list-style: none;
}

.featureLi::before{
    content: "";
    color: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #daaa84;
    margin-left: -20px;
    margin-right: 10px;
    font-weight: 900;
    display: inline-block;
}

.bookingPopupCont, .loadingCont, .thanksCont{
    position: fixed;
    bottom: 0vh;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(218, 171, 132, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 55;
    transition: all 0.5s ease-in-out;
}

.bookingpopup, .loadingpopup, .thankspopup{
    min-height: 75vh;
    width: min(100%, 600px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: white;
    animation: popupanimation 0.5s ease-out;
}

.loadingpopup{
    min-height: 40vh;
}

@keyframes popupanimation {
    0%{
        margin-bottom: -70vh;
    }100%{
        margin-bottom: 0px;
    }
}

.popupLogo{
    width: 40%;
}

.popupSogen{
    font-size: 19px;
    line-height: 17px;
}

.myDatepicker{
    border-width: 2px !important;
    width: 55%;
}

.addInpu{
    border: none;
    outline: none;
    box-shadow: none;
}

.mylegend{
    margin-top: -9px;
    width: max-content;
    font-size: 12px;
}

.bookBtn{
    border-radius: 10px;
}

.datepickerWrap{
    width: 56%;
    /* background-color: #daaa84; */
}

.mydatePicker{
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
}

.extarDiv{
    height: 100%;
    width: 100%;
}

.loaderImg{
    width: 30%;
    animation: loaderAnimation 2s infinite ease-out;
}

/* .loaderImg:hover{
    transform: rotate(760deg);
} */

.loaderImg img{
    animation: 5s infinite ease-out;
}

@keyframes loaderAnimation {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.doneimg{
    width: 40%;
}

.mainImgWrap{
    min-height: 288px;
    width: 100vw;
}

.mainImgWrap{
    transition: opacity 2s ease-in-out !important;
}

.mainImgImg{
    animation: mainImganimation 0.5s;
}

@keyframes mainImganimation {
    0%{
        margin-left: -100vw;
    }100%{
        margin-left: 0px;
    }
}

@media screen and (min-width: 500px) {
    .detailProContainer{
        border-radius: 0px !important;
    }
    .mainImgWrap{
        min-height: auto;
        width: auto;
    }
}

@media screen and (min-width: 770px) {
    .data-container{
        height: 100vh;
        overflow-y: auto;
    }
    .data-container::-webkit-scrollbar{
        display: none;
    }
    .detailPriceTag{
        width: max-content !important;
    }
    .checkBtnWrap{
        position: unset ;
    }
    .checkBtn{
        width: max-content !important;
        font-size: 16px !important;
        padding: 7px 30px !important;
    }
    .checkCall i, .checkWhat i{
        font-size: 16px !important;
    }
    .checkCall, .checkWhat{
        padding: 7px 15px !important
    }
    .checkBtnWrap{
        width: auto;
        margin-left: 10px;
    }
    .mainDetailHeader{
        text-align: start;
    }
    .bookingpopup, .loadingpopup, .thankspopup{
        min-height: 85vh;
    }
}

.crossBtn{
    cursor: pointer;
}

.accordion-button:not(.collapsed){
    background-color: #ffe8d5;
    /* z-index: -1 !important  ; */
    z-index: 0 !important;
}
.accordion-button .collapsed{
    background-color: #ffe8d5;
    /* z-index: -1 !important  ; */
    z-index: 0 !important;
}